import type { ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';
import { ALIGNMENT_CENTER } from '../ComponentTypes';
import { useIsMobile } from '../../hooks/useSize';
import { useIsInitialRequestMobile } from '../../WebshopContext/WebshopContext';

interface HeadingProps {
    alignment: string;
    hideOnMobile: boolean;
    isH1: boolean;
    text: string;
    textWrapper?: ReactElement;
    title: string;
    titleWrapper?: ReactElement;
}

const Heading: React.FC<HeadingProps> = ({
    alignment, hideOnMobile, isH1, text, textWrapper, title, titleWrapper,
}) => {
    const titleTag = isH1 ? <h1>{title}</h1> : <h2>{title}</h2>;
    const isMobile = useIsMobile(useIsInitialRequestMobile());

    if (!titleWrapper && !title) {
        return null;
    }

    if (!isH1 && hideOnMobile && isMobile) {
        return null;
    }

    return (
        <div className="section section--heading">
            <div className="wrapper">
                <div className={classNames(
                    'content content--fluid content__text--light',
                    { 'is-centered': alignment === ALIGNMENT_CENTER },
                )} data-testid="heading-content">
                    {titleWrapper
                        ? React.cloneElement(titleWrapper, {}, titleTag)
                        : titleTag
                    }

                    {textWrapper
                        ? React.cloneElement(textWrapper, {}, <p>{text}</p>)
                        : <p className="hide-xs">{text}</p>
                    }
                </div>
            </div>
        </div>
    );
};

export default Heading;
