import type React from 'react';
import classNames from 'classnames';
import Section from '../../Section/Section';
import type { Image, ImageSetup } from '../ComponentTypes';
import { ONE_IMAGE_LARGE, THREE_IMAGES_LARGE, THREE_IMAGES_SMALL, ONE_IMAGE } from '../ComponentTypes';
import { useIsInitialRequestMobile, useWithStaticUrl } from '../../WebshopContext/WebshopContext';
import styles from './ImageLayout.module.scss';
import Picture from '../../Picture/Picture';
import Source from '../../Picture/Source';
import { useIsMobile } from '../../hooks/useSize';

type LayoutRatioItem = {
    imageHeight: number;
    imageWidth: number;
};

type LayoutRatios = {
    [key in ImageSetup]: {
        firstImage: LayoutRatioItem;
        items: LayoutRatioItem[];
    };
};

interface ImageLayoutProps {
    images: Image[];
    isEditable: boolean;
    setupId: ImageSetup;
}

const ImageLayout: React.FC<ImageLayoutProps> = ({ images, isEditable, setupId }) => {
    const withStaticUrl = useWithStaticUrl();
    const isMobile = useIsMobile(useIsInitialRequestMobile());

    if (isMobile && setupId === ONE_IMAGE) {
        return null;
    }

    const hasImagesWithUri = images.some((item) => item.imageUri);
    if (!isEditable && !hasImagesWithUri) {
        return null;
    }

    const layoutRatios: LayoutRatios = {
        [ONE_IMAGE]: { firstImage: { imageHeight: 240, imageWidth: 1360 }, items: [] },
        [ONE_IMAGE_LARGE]: { firstImage: { imageHeight: 560, imageWidth: 1360 }, items: [] },
        [THREE_IMAGES_LARGE]: { firstImage: { imageHeight: 668, imageWidth: 668 }, items: [{ imageHeight: 322, imageWidth: 668 }, { imageHeight: 322, imageWidth: 668 }] },
        // eslint-disable-next-line sort-keys-fix/sort-keys-fix
        [THREE_IMAGES_SMALL]: { firstImage: { imageHeight: 480, imageWidth: 900 }, items: [{ imageHeight: 228, imageWidth: 436 }, { imageHeight: 228, imageWidth: 436 }] },
    };

    const layoutRatio = layoutRatios[setupId];
    if (!layoutRatio) {
        return null;
    }

    const renderPicture = (index: number, item: LayoutRatioItem) => {
        const imageUri = images[index]?.imageUri;

        return imageUri ? <>
            <Picture alt="" imageUrl={withStaticUrl(imageUri)}>
                <Source framing={false} minScreenWidth={992} imageWidth={item.imageWidth} imageHeight={item.imageHeight} forceHeight={true}/>
                <Source framing={false} imageWidth={992} minScreenWidth={768}/>
                <Source framing={false} imageWidth={768} minScreenWidth={390}/>
                <Source framing={false} imageWidth={390}/>
            </Picture>
            { images[index]?.url && <a className="expanded-link" href={images[index]?.url}></a>}
        </> : <div className={styles.ImageLayout_skeleton} style={{ height: item.imageHeight }}></div>;
    };

    return (<Section isXl>
        <div className={classNames(
            styles.ImageLayout,
            { [styles.ImageLayout_one_small as string]: setupId === ONE_IMAGE },
            { [styles.ImageLayout_one_large as string]: setupId === ONE_IMAGE_LARGE },
            { [styles.ImageLayout_three_small as string]: setupId === THREE_IMAGES_SMALL },
            { [styles.ImageLayout_three_large as string]: setupId === THREE_IMAGES_LARGE },
        )}>
            <div>
                { renderPicture(0, layoutRatio.firstImage) }
            </div>
            {!!layoutRatio.items.length && <div className={classNames(
                styles.ImageLayout_three__items,
                { [styles.ImageLayout_three_small_items as string]: setupId === THREE_IMAGES_SMALL },
                { [styles.ImageLayout_three_large_items as string]: setupId === THREE_IMAGES_LARGE },
            )}>
                {layoutRatio.items.map((item, index) => <div key={index} className={styles.ImageLayout_three__items_item}>
                    { renderPicture(index + 1, item) }
                </div>)}
            </div>}
        </div>
    </Section>
    );
};

export default ImageLayout;
