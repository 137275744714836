import type { ReactElement } from 'react';
import React from 'react';
import styles from './BoxSlider.module.scss';
import Slider from '../../Slider/Slider';
import Section from '../../Section/Section';

interface BoxSliderProps {
    addButton?: ReactElement;
    boxes: ReactElement[];
    editable: boolean;
    isLocked: boolean;
    title: string;
}

const BoxSlider = React.forwardRef<HTMLDivElement, BoxSliderProps>(({ addButton, boxes, editable, isLocked, title }, ref) => <Section noPadding hasBgGrey isMtXl ref={ref}>
    <div className={styles.BoxSlider}>
        {title && <h2 className={styles.BoxSlider__title}>{ title }</h2> }
        {!editable && <Slider>
            {boxes}
        </Slider>}
        {editable && isLocked && <Slider>
            {boxes}
            {addButton}
        </Slider>}
    </div>
</Section>);

export default BoxSlider;
