import debounce from 'debounce';
import { document, window } from '../globals';

export const slideScroll = (scrollEl: Element | null | undefined): (() => void) => {
    const slideScrollEl = document?.querySelector('.slide-scroll');
    const slideScrollEnd = document?.querySelector('.slide-scroll--end') as HTMLElement;
    const slideScrollStart = document?.querySelector('.slide-scroll--start') as HTMLElement;
    if (!(scrollEl instanceof HTMLElement) || !slideScrollEl || !slideScrollEnd || !slideScrollStart) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {
        };
    }
    const scrollListen = debounce(() => {
        const isAtEnd: boolean = (scrollEl.scrollWidth - 16) <= (scrollEl.offsetWidth + scrollEl.scrollLeft);
        const isScrolled: boolean = scrollEl.scrollLeft <= 0;

        slideScrollStart.style.opacity = isScrolled ? '0' : '1';
        slideScrollStart.style.pointerEvents = isScrolled ? 'none' : 'all';
        slideScrollEnd.style.opacity = isAtEnd ? '0' : '1';
        slideScrollEnd.style.pointerEvents = isAtEnd ? 'none' : 'all';
    }, 50);
    const scrollElementRight = () => {
        // eslint-disable-next-line no-param-reassign
        scrollEl.scrollLeft += scrollEl.offsetWidth;
    };
    const scrollElementLeft = () => {
        // eslint-disable-next-line no-param-reassign
        scrollEl.scrollLeft -= scrollEl.offsetWidth;
    };

    scrollListen();
    window?.addEventListener('resize', scrollListen);
    window?.addEventListener('DOMContentLoaded', scrollListen);
    scrollEl.addEventListener('scroll', scrollListen);
    slideScrollEnd.addEventListener('click', scrollElementRight);
    slideScrollStart.addEventListener('click', scrollElementLeft);

    return () => {
        window?.removeEventListener('DOMContentLoaded', scrollListen);
        scrollEl.removeEventListener('scroll', scrollListen);
    };
};
