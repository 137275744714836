import { useContext } from 'react';
import type React from 'react';
import styles from './GiftyHelper.module.scss';
import Button from '../../../../stories/Atoms/Button/Button';
import ContentBlock from '../../../../stories/Molecules/ContentBlock/ContentBlock';
import Picture from '../../Picture/Picture';
import Source from '../../Picture/Source';
import { WebshopContext } from '../../WebshopContext/WebshopContextProvider';
import Icon from '../../../../stories/Atoms/Icon/Icon';
import GiftyChat from '../../../../js/gifty/gifty';

const GiftyHelper: React.FC = () => {
    const { siteUrl } = useContext(WebshopContext);

    const openGiftyChat = () => {
        GiftyChat.loadScript().then((cxId) => {
            const { open } = GiftyChat.toggleChat(cxId);
            if (open) {
                GiftyChat.sendEvent(cxId, 'startGiftFinderChristmas');
            }
        }).catch(() => null);
    };

    return (
        <div className={styles.GiftyHelper}>
            <div className={styles.GiftyHelper__image}>
                <Picture alt="Kerstcadeau 2024" imageUrl={`${siteUrl}assets/images/abtest/christmas-2024.jpg`} lazyLoad={false}>
                    <Source imageWidth={768} imageHeight={320} minScreenWidth={768} />
                    <Source imageWidth={320} imageHeight={200} />
                </Picture>
            </div>
            <div className={styles.GiftyHelper__content}>
                <div className={styles.GiftyHelper__content__block}>
                    <Icon name="fa-tree-christmas" style="fa-duotone" size="fa-xl"/>
                    <ContentBlock className="mt-6 mb-6" title="Nieuw" content="Onze enige echte santa-helper is er! Wij staan te popelen om jouw cadeau te helpen vinden." />
                    <Button onClick={openGiftyChat} rounded>Vind jouw cadeau</Button>
                </div>
            </div>
        </div>
    );
};

export default GiftyHelper;
