import React from 'react';
import classNames from 'classnames';
import styles from './ContentBlock.module.scss';

interface Props {
    className?: string;
    content?: string | JSX.Element;
    isContentSmall?: boolean;
    title?: string | JSX.Element;
}

const ContentBlock: React.FC<Props> = ({ className, content, isContentSmall, title }) => {
    if (!content && !title) {
        return null;
    }

    return (
        <div className={[styles.ContentBlock, className].join(' ')}>
            {title && <p className={styles.ContentBlock__subtitle}>{title}</p>}
            {content && <p className={classNames(
                styles.ContentBlock__content,
                { [styles.ContentBlock__content_small as string]: isContentSmall },
            )}>
                {content}
            </p>}
        </div>
    );
};

export default ContentBlock;
