import React, { useContext } from 'react';
import type { ReactElement } from 'react';
import type { Categories as CategoriesType } from '../ComponentTypes';
import ImageSlider from './ImageSlider';
import ButtonSlider from './ButtonSlider';
import Section from '../../Section/Section';
import { WebshopContext } from '../../WebshopContext/WebshopContextProvider';
import { ChristmasLayoutVariations } from '../../../../js/optimizely/fx/flags';
import GiftyHelper from '../GiftyHelper/GiftyHelper';

interface CategoriesProps {
    categories: CategoriesType['links'];
    draggableWrapper?: ReactElement;
    isDraggable?: boolean;
    itemWrapper?: ReactElement;
    withImages: boolean;
}

const Categories = React.forwardRef<HTMLDivElement, CategoriesProps>(({ categories, draggableWrapper, isDraggable, itemWrapper, withImages }, ref) => {
    const hasChristmasLayoutVariationB = (useContext(WebshopContext).experimentVariation === ChristmasLayoutVariations.variationB);
    const hasChristmasLayoutVariationC = (useContext(WebshopContext).experimentVariation === ChristmasLayoutVariations.variationC);
    const shownCategories = categories.filter((c) => itemWrapper || c.show);

    if (!shownCategories.length || hasChristmasLayoutVariationB) {
        return null;
    }

    return <Section noPadding hasBorderBottom={!hasChristmasLayoutVariationC} ref={ref}>
        {!hasChristmasLayoutVariationC && (withImages
            ? <ImageSlider categories={shownCategories} draggableWrapper={draggableWrapper} isDraggable={isDraggable} itemWrapper={itemWrapper} />
            : <ButtonSlider categories={shownCategories} draggableWrapper={draggableWrapper} isDraggable={isDraggable} itemWrapper={itemWrapper} />
        )}
        {hasChristmasLayoutVariationC && <GiftyHelper/>}
    </Section>;
});

export default Categories;
