import type { Thing } from 'schema-dts';

type LdJsonProps<T extends Thing > = {
    jsonData: T extends { '@context' : string } | string ? never : T;
};

const LdJson = <T extends Thing>({ jsonData }: LdJsonProps<T>) => <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify({
        '@context': 'https://schema.org',
        ...jsonData,
    }) }}
/>;

export default LdJson;
