import React from 'react';
import { useConfigure } from 'react-instantsearch-core';
import type { Tracker } from '@yoursurprise/segment-analytics';
import SearchPage from '../../general/SearchPage/SearchPage';
import useHasAnalytics from '../../general/Analytics/useHasAnalytics';
import { convertSetup } from './convertSetup';
import type { PageSetup } from '../../../general/Components/ComponentTypes';
import { addDeviceTag } from '../../general/Analytics/Analytics';
import { HITS_PER_PAGE } from '../../general/config';

export interface ProductCategoryPageProps {
    collapsed?: boolean;
    currency: string;
    distinct: boolean;
    editableButton?: React.ReactElement;
    hasFilter?: boolean;
    imageOverrides: Record<number, string>;
    limit?: number;
    pageId: number;
    pageTypeSlug: string;
    photoGiftUrl: string;
    productIndexName: string;
    replicaIndexBase: string;
    setup?: PageSetup;
    tracker?: Tracker | undefined;
}

const ProductCategoryPage = React.forwardRef<HTMLDivElement, ProductCategoryPageProps>(({
    collapsed,
    currency,
    distinct,
    editableButton,
    hasFilter,
    imageOverrides,
    limit,
    pageId,
    pageTypeSlug,
    photoGiftUrl,
    productIndexName,
    replicaIndexBase,
    setup,
    tracker,
}, ref) => {
    const analytics = useHasAnalytics();
    const { filter, index } = convertSetup(setup, productIndexName, replicaIndexBase);
    const useCustomIndex = setup?.type === 'bestsellers' || setup?.type === 'new-products';

    useConfigure({
        analytics,
        analyticsTags: addDeviceTag([`page:${pageTypeSlug}`, `page_id:${pageId}`]),
        clickAnalytics: analytics,
        distinct,
        filters: filter,
        getRankingInfo: true,
        hitsPerPage: limit || HITS_PER_PAGE,
        index: useCustomIndex ? index : undefined,
        ruleContexts: addDeviceTag([`page_${pageTypeSlug}`, `page_id_${pageId}`, `setup_${setup?.type ?? 'none'}`], true),
    });

    return <SearchPage
        collapsed={collapsed}
        editableButton={editableButton}
        currency={currency}
        hasFilter={hasFilter}
        distinct={distinct}
        photoGiftUrl={photoGiftUrl}
        productIndexName={productIndexName}
        ref={ref}
        replicaIndexBase={replicaIndexBase}
        tracker={tracker}
        imageOverrides={imageOverrides}
        hidePagination={!!limit}
        limit={limit}
    />;
});

export default ProductCategoryPage;
