import React from 'react';
import classNames from 'classnames';
import type { Promotion as PromotionComponentData } from '../ComponentTypes';
import { ALIGNMENT_ROW, ALIGNMENT_BOTTOM, ALIGNMENT_LEFT, GRADIENT_GREEN, GRADIENT_RED, GRADIENT_YELLOW, SECTION_TYPE_SM, SECTION_TYPE_XL, GRADIENT_BLUE, GRADIENT_GREY } from '../ComponentTypes';
import { useWithStaticUrl } from '../../WebshopContext/WebshopContext';
import Button from '../../Button/Button';
import Picture from '../../Picture/Picture';
import styles from './Promotion.module.scss';
import Block from '../../Section/Block';
import Source from '../../Picture/Source';

interface PromotionProps {
    component: PromotionComponentData;
    editable: boolean;
}

const Promotion = React.forwardRef<HTMLDivElement, PromotionProps>(({ component, editable }, ref) => {
    const {
        alignment, alignmentBannerMobile, alignmentBlocksMobile, bannerAlt, bannerButtonText, bannerImageUri, bannerTextPosition, bannerTitle, bannerUrl, lazyLoad, promotionItems,
    } = component;
    const withStaticUrl = useWithStaticUrl();
    const layoutBannerRight = alignment === ALIGNMENT_LEFT;
    const items = promotionItems.filter((i) => !!i.title);

    if (!editable && !bannerTitle && !items.length) {
        return null;
    }

    return (
        <div className={classNames(
            'section',
            { 'section--sm': component.sectionType === SECTION_TYPE_SM },
            { 'section--xl': component.sectionType === SECTION_TYPE_XL },
        )}>
            <div className="wrapper" ref={ref}>
                <div className={classNames(
                    styles.Promotion,
                    { [styles.Promotion_right as string]: layoutBannerRight },
                    { [styles.Promotion_only as string]: !items.length },
                )}>
                    <div className={classNames(
                        styles.Promotion__banner,
                        { [styles.Promotion__banner_bottom_mobile as string]: alignmentBannerMobile === ALIGNMENT_BOTTOM },
                    )} data-testid="banner">
                        <Block blockLink blockSquare customClassNames={classNames(
                            styles.Promotion__banner__image,
                            { [styles.Promotion__banner__image_gradient as string]: bannerTextPosition === 'none' },
                        )}>
                            {bannerImageUri && <Picture lazyLoad={lazyLoad} imageUrl={withStaticUrl(bannerImageUri)} alt={bannerAlt ?? bannerTitle}>
                                <Source imageHeight={464} imageWidth={892} minScreenWidth={768} ratio='2:1' framing='crop'/>
                                <Source imageHeight={300} imageWidth={500} minScreenWidth={500} ratio='2:1' framing='crop'/>
                                <Source imageHeight={200} imageWidth={390} ratio='2:1' framing='crop'/>
                            </Picture>
                            }
                            {bannerUrl && <a aria-label="Go to link" href={bannerUrl}></a>}
                        </Block>
                        <div data-testid="banner-content" className={classNames(
                            styles.Promotion__banner__content as string,
                            { [styles.Promotion__banner__content_none as string]: bannerTextPosition === 'none' },
                            { [styles.Promotion__banner__content_top as string]: bannerTextPosition === 'top' },
                            { [styles.Promotion__banner__content_center as string]: bannerTextPosition === 'center' },
                            { [styles.Promotion__banner__content_bottom as string]: bannerTextPosition === 'bottom' },
                        )}>
                            <p className={classNames({ h1: bannerTextPosition === 'none' }, { h3: bannerTextPosition !== 'none' })}>{bannerTitle}</p>
                            <Button
                                className={classNames('mt-6', styles.Promotion__banner__content__button as string)}
                                {...(bannerUrl ? { href: bannerUrl } : {})} isPrimary>{bannerButtonText}</Button>
                        </div>
                    </div>
                    <div data-testid="promotions"
                        className={classNames(
                            [styles.Promotion__blocks as string],
                            { [styles.Promotion__blocks_asRow as string]: alignmentBlocksMobile === ALIGNMENT_ROW },
                            'has-col-1 has-row-2 o-h',
                        )}>
                        {items.map((item) => (
                            <Block key={item.id} blockBoxed blockLink customClassNames={classNames(
                                `is-row-1 ${styles.Promotion__blocks__item as string}`,
                                { [styles['Promotion__blocks__item--blue'] as string]: item.gradient === GRADIENT_BLUE },
                                { [styles['Promotion__blocks__item--green'] as string]: item.gradient === GRADIENT_GREEN },
                                { [styles['Promotion__blocks__item--grey'] as string]: item.gradient === GRADIENT_GREY },
                                { [styles['Promotion__blocks__item--red'] as string]: item.gradient === GRADIENT_RED },
                                { [styles['Promotion__blocks__item--yellow'] as string]: item.gradient === GRADIENT_YELLOW },
                            )}>
                                <div className={`content content__text--light ${styles.Promotion__blocks__item__content as string}`}
                                    data-testid={`promotion-content-${item.id}`}>
                                    <p className="h3">{item.title}</p>
                                    <p>{item.text}</p>
                                </div>
                                {item.imageUri && <div className={styles.Promotion__blocks__item__image}>
                                    <Picture imageUrl={withStaticUrl(item.imageUri)} lazyLoad={lazyLoad} alt={item.alt ?? item.title}>
                                        <Source background={false} imageWidth={156} minScreenWidth={768}/>
                                        <Source background={false} imageWidth={140}/>
                                    </Picture>
                                </div>}
                                {!editable && item.url && <a aria-label="go to link" href={item.url}></a>}
                            </Block>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Promotion;
