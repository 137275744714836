import React from 'react';
import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import styles from './Heading.module.scss';

export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface Props {
    asText?: boolean;
    className?: string;
    isBold?: boolean;
    isPrimary?: boolean;
    tag: HeadingTag;
}

const Heading: React.FC<PropsWithChildren<Props>> = ({
    asText = false, children, className, isBold, isPrimary, tag,
}) => {
    const headingStyle = classNames(
        styles.Heading,
        styles[`Heading_${tag}`] as string,
        className,
        { [styles.Heading_isPrimary as string]: isPrimary },
        { [styles.Heading_isBold as string]: isBold },
    );

    return (
        asText ? <span className={headingStyle}>{children}</span>
            : React.createElement(tag, { className: headingStyle }, children)
    );
};

export default Heading;
