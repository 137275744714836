import React, { useContext } from 'react';
import type { ReactElement } from 'react';
import { useWithStaticUrl } from '../../WebshopContext/WebshopContext';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Picture from '../../Picture/Picture';
import Source from '../../Picture/Source';
import { WebshopContext } from '../../WebshopContext/WebshopContextProvider';
import { ChristmasLayoutVariations } from '../../../../js/optimizely/fx/flags';
import CategoryBlock from '../CategoryBlock/CategoryBlock';
import useTranslate from '../../Translation/hooks/UseTranslate';

interface HeaderProps {
    alt: string;
    breadcrumbs?: Record<string, string>;
    image: string;
    isSmartImage: boolean;
    title: string;
    titleWrapper?: ReactElement;
}

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(({
    alt,
    breadcrumbs,
    image,
    isSmartImage,
    title,
    titleWrapper,
}, ref) => {
    const translate = useTranslate();
    const withStaticUrl = useWithStaticUrl();
    const variationHimHer = (useContext(WebshopContext).experimentVariation) === ChristmasLayoutVariations?.variationB;

    if (!image) {
        return (
            <div className="page-header" ref={ref}>
                <div className="wrapper">
                    {variationHimHer && (
                        <CategoryBlock>
                            <CategoryBlock.Item colorScheme="blue" itemUrl={translate('overview_page', 'abtest_christmas_item_url_him')}
                                imageUrl="https://static.yoursurprise.com/bndr/m/3dcb884707f20c5c/original/for-him_round.png"
                                title={translate('overview_page', 'abtest_christmas_item_title_him')}
                                subtitle={translate('overview_page', 'abtest_christmas_item_subtitle')}
                            />
                            <CategoryBlock.Item colorScheme="pink" itemUrl={translate('overview_page', 'abtest_christmas_item_url_her')}
                                imageUrl="https://static.yoursurprise.com/bndr/m/34bb0f1630e8ac96/original/for-her_round.png"
                                title={translate('overview_page', 'abtest_christmas_item_title_her')}
                                subtitle={translate('overview_page', 'abtest_christmas_item_subtitle')}
                            />
                        </CategoryBlock>
                    )}
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    {!!title && (
                        <div className="page-heading">
                            {title && (
                                React.cloneElement(
                                    titleWrapper ?? <>{}</>,
                                    {},
                                    <h1 data-testid="header-title">{title}</h1>,
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="page-header" ref={ref}>
            <div className="wrapper">
                <div className="hero hero--static">
                    <div className="hero__image">
                        <Picture alt={alt || title} lazyLoad={false} imageUrl={`${withStaticUrl(image)}`}>
                            <Source framing={false} imageHeight={320} imageWidth={1360} minScreenWidth={1360} ratio="2:1"/>
                            <Source framing={false} imageHeight={320} imageWidth={992} minScreenWidth={992} ratio="2:1"/>
                            <Source framing={false} imageHeight={320} imageWidth={768} minScreenWidth={768} ratio="2:1"/>
                            <Source framing={false} imageHeight={240} imageWidth={576} minScreenWidth={576} ratio="2:1"/>
                            <Source framing="crop" imageHeight={240} imageWidth={390} ratio="2:1" smartImage={isSmartImage}/>
                        </Picture>
                    </div>
                </div>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <div className="page-heading">
                    {title && (
                        React.cloneElement(
                            titleWrapper ?? <>{}</>,
                            {},
                            <h1 data-testid="header-title">{title}</h1>,
                        )
                    )}
                </div>
            </div>
        </div>
    );
});

export default Header;
