import type { ReactElement } from 'react';
import React from 'react';
import Markdown from 'markdown-to-jsx';
import Section from '../../../../general/Section/Section';

interface TextProps {
    text: string;
    wrapper?: ReactElement;
}

const Text: React.FC<TextProps> = ({ text, wrapper }) => {
    const trimmedText = text.trim();

    if (!trimmedText) {
        return null;
    }

    return (
        <Section isXs>
            <div className="text-component lh-lg">
                <div className="text-component__text">
                    {wrapper
                        ? React.cloneElement(wrapper, {}, trimmedText)
                        : <Markdown options={{ disableParsingRawHTML: true }}>{trimmedText}</Markdown>}
                </div>
            </div>
        </Section>
    );
};

export default Text;
