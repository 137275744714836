export const HEADER_TYPE = 'header';
export const PRODUCTS_TYPE = 'products';
export const SEO_TEXT_TYPE = 'seo-text';
export const TEXT_TYPE = 'text';
export const CATEGORIES_TYPE = 'categories';
export const DESCRIPTION_TYPE = 'description';
export const PHOTOSLURP_TYPE = 'photoslurp';
export const PRODUCT_SLIDER_TYPE = 'product-slider';
export const USPS_TYPE = 'usps';
export const PROMOTION_TYPE = 'promotion';
export const BOXES_TYPE = 'boxes';
export const IMAGE_LAYOUT_TYPE = 'imageLayout';
export const BREADCRUMBS_TYPE = 'breadcrumbs';
export const HEADING_TYPE = 'heading';
export const NEWSLETTER_TYPE = 'newsletter';

export type Header = {
    alt: string;
    breadcrumbs?: Record<string, string>;
    id: string;
    image: string;
    isSmartImage: boolean;
    squareImage: string;
    title: string;
    type: typeof HEADER_TYPE;
};

type NoSetup = {
    type: 'none';
};

export type ParentPageSetup = {
    pageId: number;
    type: 'parent';
};

export type EavSetup = {
    attributeId?: number;
    attributeName?: string;
    type: 'eav';
    valueId?: number;
    valueName?: string;
};

export type DiscountSetup = {
    type: 'discount';
};

export type CollectionSetup = {
    setups: Record<string, PageSetup>;
    type: 'collection';
};

export type BestsellerSetup = {
    type: 'bestsellers';
};

export type NewSetup = {
    type: 'new-products';
};

export type PageSetup =
    | ParentPageSetup
    | NoSetup
    | EavSetup
    | DiscountSetup
    | CollectionSetup
    | BestsellerSetup
    | NewSetup;

export const GRADIENT_GREEN = 'green';
export const GRADIENT_RED = 'red';
export const GRADIENT_GREY = 'grey';
export const GRADIENT_YELLOW = 'yellow';
export const GRADIENT_BLUE = 'blue';
export type Gradient = typeof GRADIENT_GREEN | typeof GRADIENT_RED | typeof GRADIENT_GREY | typeof GRADIENT_YELLOW | typeof GRADIENT_BLUE;

export const LAYOUT_SETUP_BASIC = 'basic';
export const LAYOUT_SETUP_ADVANCED = 'advanced';
export type LayoutSetup = typeof LAYOUT_SETUP_BASIC | typeof LAYOUT_SETUP_ADVANCED;

export const BOX_TYPE_SMALL = 'small';
export const BOX_TYPE_LARGE = 'large';
export type BoxType = typeof BOX_TYPE_SMALL | typeof BOX_TYPE_LARGE;

export const ONE_IMAGE = 1;
export const ONE_IMAGE_LARGE = 2;
export const THREE_IMAGES_SMALL = 3;
export const THREE_IMAGES_LARGE = 4;
export type ImageSetup = typeof ONE_IMAGE | typeof ONE_IMAGE_LARGE | typeof THREE_IMAGES_SMALL | typeof THREE_IMAGES_LARGE;

export const ALIGNMENT_CENTER = 'center';
export const ALIGNMENT_RIGHT = 'right';
export const ALIGNMENT_LEFT = 'left';
export const ALIGNMENT_BOTTOM = 'bottom';
export const ALIGNMENT_TOP = 'top';

export type Alignment = typeof ALIGNMENT_CENTER | typeof ALIGNMENT_RIGHT | typeof ALIGNMENT_LEFT;

export type AlignmentBannerMobile = typeof ALIGNMENT_BOTTOM | typeof ALIGNMENT_TOP;

export const ALIGNMENT_ROW = 'row';
export const ALIGNMENT_COLUMN = 'column';

export type AlignmentBlocksMobile = typeof ALIGNMENT_ROW | typeof ALIGNMENT_COLUMN;

export const SECTION_TYPE_XL = 'xl';
export const SECTION_TYPE_SM = 'sm';
export type SectionType = typeof SECTION_TYPE_XL | typeof SECTION_TYPE_SM;

export const TEXT_POSITION_TOP = 'top';
export const TEXT_POSITION_CENTER = 'center';
export const TEXT_POSITION_BOTTOM = 'bottom';
export const TEXT_POSITION_NONE = 'none';
export type TextPosition = typeof TEXT_POSITION_TOP | typeof TEXT_POSITION_CENTER | typeof TEXT_POSITION_BOTTOM | typeof TEXT_POSITION_NONE;

export type Products = {
    distinct: boolean;
    hasFilter: boolean;
    id: string;
    imageOverrides: Record<number, string>;
    limit?: number;
    setup: PageSetup;
    type: typeof PRODUCTS_TYPE;
};

export type SeoText = {
    id: string;
    text: string;
    type: typeof SEO_TEXT_TYPE;
};

export type Text = {
    id: string;
    text: string;
    type: typeof TEXT_TYPE;
};

export type CategoryLinks = Array<{ id: string; imageUri: string; isAdditional: boolean; name: string; pageId: number; show: boolean; url: string }>;

export type Categories = {
    id: string;
    links: CategoryLinks;
    type: typeof CATEGORIES_TYPE;
    withImages: boolean;
};

export type Description = {
    id: string;
    text: string;
    type: typeof DESCRIPTION_TYPE;
};

export type Photoslurp = {
    id: string;
    photoslurpId: string;
    type: typeof PHOTOSLURP_TYPE;
};

export type ProductSlider = {
    buttonPageId?: string;
    buttonPageIdPlaceholder?: string;
    buttonText: string;
    buttonUrl?: string;
    gradient?: Gradient;
    id: string;
    layoutSetup: LayoutSetup;
    setup: PageSetup;
    title: string;
    type: typeof PRODUCT_SLIDER_TYPE;
};

export type Usp = {
    id: string;
    imageUri: string;
    pageId: number;
    pageIdPlaceholder?: string;
    text: string;
    title: string;
    url?: string;
};

export type Usps = {
    id: string;
    items: Usp[];
    layoutSetup: LayoutSetup;
    title: string;
    type: typeof USPS_TYPE;
};

export type PromotionItem = {
    alt?: string;
    gradient: Gradient;
    id: string;
    imageUri: string;
    pageId: number;
    pageIdPlaceholder?: string;
    text: string;
    title: string;
    url?: string;
};

export type Promotion = {
    alignment: Alignment;
    alignmentBannerMobile: AlignmentBannerMobile;
    alignmentBlocksMobile: AlignmentBlocksMobile;
    bannerAlt?: string;
    bannerButtonText: string;
    bannerImageUri: string;
    bannerPageId: number;
    bannerPageIdPlaceholder?: string;
    bannerTextPosition: TextPosition;
    bannerTitle: string;
    bannerUrl?: string;
    id: string;
    lazyLoad: boolean;
    promotionItems: PromotionItem[];
    sectionType: SectionType;
    type: typeof PROMOTION_TYPE;
};

export type Box = {
    id: string;
    imageUri: string;
    pageId: number;
    pageIdPlaceholder?: string;
    title: string;
    url?: string;
    urlParams?: string;
};

export type Boxes = {
    asSlider: boolean;
    boxItems: Box[];
    boxType: BoxType;
    editableTitles: boolean;
    id: string;
    title: string;
    type: typeof BOXES_TYPE;
};

export type Image = {
    id: string;
    imageUri: string;
    pageId: number;
    pageIdPlaceholder?: string;
    url?: string;
    urlParams?: string;
};

export type ImageLayout = {
    id: string;
    images: Image[];
    setupId: ImageSetup;
    type: typeof IMAGE_LAYOUT_TYPE;
};

export type Breadcrumbs = {
    breadcrumbs: Record<string, string>;
    id: string;
    type: typeof BREADCRUMBS_TYPE;
};

export type Heading = {
    alignment: Alignment;
    hideOnMobile: boolean;
    id: string;
    isH1: boolean;
    text: string;
    title: string;
    type: typeof HEADING_TYPE;
};

export type NewsletterUsp = {
    id: string;
    text: string;
};

export type Newsletter = {
    buttonText: string;
    description: string;
    id: string;
    successButtonText: string;
    successText: string;
    successTitle: string;
    title: string;
    type: typeof NEWSLETTER_TYPE;
    usps: NewsletterUsp[];
};

export type Component =
    | Header
    | Products
    | SeoText
    | Categories
    | Description
    | Photoslurp
    | ProductSlider
    | Usps
    | Promotion
    | Boxes
    | ImageLayout
    | Breadcrumbs
    | Heading
    | Text
    | Newsletter;

export type Components = Component[];

export type PartialComponent<T extends Component = Component> = Partial<T> & Pick<T, 'id'>;
