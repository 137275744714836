import React from 'react';
import classNames from 'classnames';
import styles from './Block.module.scss';

type BlockProps = {
    blockBoxed?: boolean;
    blockLink?: boolean;
    blockSquare?: boolean;
    children: React.ReactNode;
    customClassNames?: string;
};

const Block = React.forwardRef<HTMLDivElement, BlockProps>(({
    blockBoxed,
    blockLink,
    blockSquare,
    children,
    customClassNames,
}, ref) => (
    <div data-testid='block' ref={ref} className={classNames(styles.Block, {
        [styles['Block--boxed'] as string]: blockBoxed,
        [styles['Block--link'] as string]: blockLink,
        [styles['Block--square'] as string]: blockSquare,
    }, customClassNames)}>
        {children}
    </div>
));

export default Block;
