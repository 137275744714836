export const ChristmasLayoutVariations = {
    variationA: 'slider',
    variationB: 'him_her',
    variationC: 'gifty',
};

export const cartPageDeleteTest = {
    flagKey: 'b4-cart-delete',
    variationA: 'icon',
    variationB: 'text',
};
