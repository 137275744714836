import type React from 'react';
import Dialog from '../../../../stories/Molecules/Dialog/Dialog';
import Heading from '../../../../stories/Atoms/Heading/Heading';
import ContentBlock from '../../../../stories/Molecules/ContentBlock/ContentBlock';
import Icon from '../../../../stories/Atoms/Icon/Icon';
import Flex from '../../../../stories/Atoms/Flex/Flex';

type Props = {
    handleCloseDialog: () => void;
    successText: string;
    successTitle: string;
};

const NewsletterDialog: React.FC<Props> = ({ handleCloseDialog, successText, successTitle }) => (
    <Dialog centered onClose={handleCloseDialog} open closeButton>
        <Dialog.Header>
            <Flex direction="column" alignItems="center" rowGap="s6">
                <Icon name="fa-envelope-open-text" style="fa-light" size="fa-2xl"/>
                <Heading tag="h4" asText>{successTitle}</Heading>
            </Flex>
        </Dialog.Header>
        <Dialog.Body>
            <ContentBlock className="mt-6 mb-6" content={successText} />
        </Dialog.Body>
    </Dialog>
);

export default NewsletterDialog;
