import type { PageSetup } from '../../../general/Components/ComponentTypes';

type Setup = {
    filter?: string;
    hidden?: boolean;
    index: string;
};

export const convertSetup = (setup: PageSetup | undefined, currentIndex: string, replicaIndexBase: string): Setup => {
    switch (setup?.type) {
        case 'parent':
            return { filter: `filterData.parents: ${setup.pageId}`, index: currentIndex };
        case 'eav':
            return { filter: `filterData.eav.${setup.attributeName ?? ''}.value_name: "${(setup.valueName ?? '')}"`, index: currentIndex };
        case 'discount':
            return { filter: 'filterData.hasDiscount: true', index: currentIndex };
        case 'collection': {
            const setups: string[] = [];
            Object.values(setup.setups).map((internalSetup) => {
                const convertedSetup = convertSetup(internalSetup, currentIndex, replicaIndexBase);
                if (convertedSetup.filter !== undefined) {
                    setups.push(convertedSetup.filter);
                }

                return true;
            });

            return { filter: setups.join(' AND '), index: currentIndex };
        }
        case 'bestsellers':
            return { index: `${replicaIndexBase}-bestseller_desc` };
        case 'new-products':
            return { index: `${replicaIndexBase}-online_date_desc` };
        case 'none':
        default:
            return { index: currentIndex };
    }
};
