import type React from 'react';
import type { BreadcrumbList, ListItem } from 'schema-dts';
import LdJson from '../../LdJson/LdJson';

interface BreadcrumbsProps {
    breadcrumbs?: Record<string, string>;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
    if (!breadcrumbs) {
        return null;
    }
    const breadcrumbData = Object.entries(breadcrumbs);

    if (breadcrumbData.length === 0) {
        return null;
    }

    const jsonData: BreadcrumbList = {
        '@type': 'BreadcrumbList',
        itemListElement:
            [
                ...breadcrumbData.map<ListItem>(([url, description], i) => ({
                    '@type': 'ListItem',
                    item: { '@id': url, name: description },
                    position: i + 1,
                })),
            ],

    };

    return (
        <div className="breadcrumbs">
            <ul>
                {breadcrumbData.map(([url, description], i) => (
                    i < breadcrumbData.length - 1
                        ? <li key={description}><a href={url}><span>{description}</span></a></li>
                        : <li key={description}><span>{description}</span></li>
                ))}
            </ul>
            <LdJson jsonData={jsonData} />
        </div>
    );
};

export default Breadcrumbs;
