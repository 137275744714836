import type React from 'react';
import { useEffect } from 'react';
import { document } from '../../../../js/globals';
import styles from './Photoslurp.module.scss';

interface PhotoslurpProps {
    locale: string;
    photoslurpId: string;
}

const Photoslurp: React.FC<PhotoslurpProps> = ({ locale, photoslurpId }) => {
    useEffect(() => {
        if (!photoslurpId) {
            return;
        }

        const photoslurpScript = document?.createElement('script');

        if (photoslurpScript) {
            photoslurpScript.setAttribute('async', '');
            photoslurpScript.setAttribute('src', 'https://photoslurp-assets.s3.eu-west-1.amazonaws.com/widget/v3/loader.js');
            document?.head.appendChild(photoslurpScript);
        }
    }, [photoslurpId]);

    if (!photoslurpId) {
        return null;
    }

    return (
        <div className="section section--md">
            <div className="wrapper o-h">
                <div className={styles.Photoslurp}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <ps-widget suppressHydrationWarning dangerouslySetInnerHTML={{ __html: '' }} data-config={photoslurpId}></ps-widget>
                </div>
            </div>
            <script dangerouslySetInnerHTML={{
                __html: `
            window.photoSlurpWidgetSettings = window.photoSlurpWidgetSettings || {};

            photoSlurpWidgetSettings["${photoslurpId}"] = { lang: "${locale}" };
            `,
            }} />
        </div>
    );
};

export default Photoslurp;
