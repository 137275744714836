import type { ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Configure, Index, useConfigure } from 'react-instantsearch-core';
import type { Tracker } from '@yoursurprise/segment-analytics';
import type { ProductSlider as ProductSliderType } from '../../../../general/Components/ComponentTypes';
import { convertSetup } from '../convertSetup';
import { ConnectedProductList } from '../../../general/ProductList/ProductList';
import useHasAnalytics from '../../../general/Analytics/useHasAnalytics';
import { GRADIENT_BLUE, GRADIENT_GREEN, GRADIENT_GREY, GRADIENT_RED, GRADIENT_YELLOW, LAYOUT_SETUP_ADVANCED } from '../../../../general/Components/ComponentTypes';
import Button from '../../../../general/Button/Button';
import styles from './ProductSlider.module.scss';
import { addDeviceTag } from '../../../general/Analytics/Analytics';
import useFixAnalytics from '../../../general/Analytics/useFixAnalytics';

type ProductsSliderProps = {
    component: ProductSliderType;
    currency: string;
    pageTypeSlug: string;
    productIndexName: string;
    replicaIndexBase: string;
    title: string;
    titleWrapper?: ReactElement;
    tracker?: Tracker | undefined;
};

const ProductSlider = React.forwardRef<HTMLDivElement, ProductsSliderProps>(({
    component,
    currency,
    pageTypeSlug,
    productIndexName,
    replicaIndexBase,
    title,
    titleWrapper,
    tracker,
}, ref) => {
    const {
        buttonText, buttonUrl, gradient, id, layoutSetup, setup,
    } = component;
    const analytics = useHasAnalytics();

    useFixAnalytics();

    const { filter, index } = convertSetup(setup, productIndexName, replicaIndexBase);
    const hasAdvancedLayoutSetup = layoutSetup === LAYOUT_SETUP_ADVANCED;
    const hasAdvancedLayoutSetupBlue = hasAdvancedLayoutSetup && gradient === GRADIENT_BLUE;
    const hasAdvancedLayoutSetupGreen = hasAdvancedLayoutSetup && gradient === GRADIENT_GREEN;
    const hasAdvancedLayoutSetupGrey = hasAdvancedLayoutSetup && gradient === GRADIENT_GREY;
    const hasAdvancedLayoutSetupRed = hasAdvancedLayoutSetup && gradient === GRADIENT_RED;
    const hasAdvancedLayoutSetupYellow = hasAdvancedLayoutSetup && gradient === GRADIENT_YELLOW;
    const maxHits = hasAdvancedLayoutSetup ? 3 : 4;

    useConfigure({
        hitsPerPage: maxHits,
        responseFields: ['hits'],
    });

    const algoliaList = <Index indexName={index} indexId={`${index}${setup.type}`}>
        <Configure
            clickAnalytics={analytics}
            getRankingInfo
            filters={filter}
            analytics={analytics}
            analyticsTags={addDeviceTag([`page:${pageTypeSlug}`])}
            ruleContexts={addDeviceTag([`page_${pageTypeSlug}`], true)}
        />
        <ConnectedProductList asSlider={true} currency={currency} distinct={true} tracker={tracker} id={id} index={productIndexName} ref={ref}/>
    </Index>;

    return (
        <div className="section section--xl">
            <div className={classNames(
                { 'section--bg-blue': hasAdvancedLayoutSetupBlue },
                { 'section--bg-green': hasAdvancedLayoutSetupGreen },
                { 'section--bg-grey': hasAdvancedLayoutSetupGrey },
                { 'section--bg-red': hasAdvancedLayoutSetupRed },
                { 'section--bg-yellow': hasAdvancedLayoutSetupYellow },
                { 'section--bg-grey section--bg-grey-wave': !hasAdvancedLayoutSetup },
            )} data-testid="wrapper-bg">
                <div className="wrapper" ref={ref}>
                    <div className={classNames(
                        styles.ProductSlider,
                        { [styles.ProductSlider__advanced as string]: hasAdvancedLayoutSetup },
                        { [styles.ProductSlider__basic as string]: !hasAdvancedLayoutSetup },
                    )} data-testid="layout-style">
                        <div className={classNames(
                            styles.ProductSlider__title,
                            { [styles['ProductSlider__title--wave'] as string]: hasAdvancedLayoutSetup },
                            { [styles['ProductSlider__title--wave--blue'] as string]: hasAdvancedLayoutSetupBlue },
                            { [styles['ProductSlider__title--wave--grey'] as string]: hasAdvancedLayoutSetupGrey },
                            { [styles['ProductSlider__title--wave--red'] as string]: hasAdvancedLayoutSetupRed },
                            { [styles['ProductSlider__title--wave--yellow'] as string]: hasAdvancedLayoutSetupYellow },
                        )}>
                            <div className="wrapper--mobile--md">
                                {React.cloneElement(
                                    titleWrapper ?? <>{}</>,
                                    {},
                                    <h2>{title}</h2>,
                                )}
                            </div>
                        </div>
                        <div className={styles.ProductSlider__button}>
                            <div className="wrapper--mobile--md">
                                {buttonText && buttonUrl && <Button aria-label="go to link" href={buttonUrl} className={classNames(
                                    'button--fluid',
                                    { 'button--secondary': hasAdvancedLayoutSetupGreen },
                                    { 'button--ghost': hasAdvancedLayoutSetupGrey || !hasAdvancedLayoutSetup },
                                    { 'button--blue': hasAdvancedLayoutSetupBlue },
                                    { 'button--primary': hasAdvancedLayoutSetupRed },
                                    { 'button--yellow': hasAdvancedLayoutSetupYellow },
                                )}>{buttonText}</Button>}
                            </div>
                        </div>
                        <div className={styles.ProductSlider__slider}>
                            {algoliaList}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ProductSlider;
