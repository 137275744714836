import type { ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';
import type { Usps as UspsComponentData } from '../ComponentTypes';
import { LAYOUT_SETUP_ADVANCED, LAYOUT_SETUP_BASIC } from '../ComponentTypes';
import { useWithStaticUrl } from '../../WebshopContext/WebshopContext';
import Picture from '../../Picture/Picture';
import styles from './Usps.module.scss';
import Source from '../../Picture/Source';

interface UspsProps {
    component: UspsComponentData;
    editable: boolean;
    title: string;
    titleWrapper?: ReactElement;
}

const Usps = React.forwardRef<HTMLDivElement, UspsProps>(({ component, editable, title, titleWrapper }, ref) => {
    const { items, layoutSetup } = component;
    const withStaticUrl = useWithStaticUrl();

    const layoutAdvanced = layoutSetup === LAYOUT_SETUP_ADVANCED;
    const layoutBasic = layoutSetup === LAYOUT_SETUP_BASIC;

    const uspItems = items.filter((i) => !!i.title);

    if (!editable && !uspItems.length) {
        return null;
    }

    return (
        <div className="section section--xl">
            <div className="wrapper" ref={ref}>
                <div className={classNames('content content__text--light is-centered', { 'hide-lg': !editable })}>
                    {(editable || title) && React.cloneElement(
                        titleWrapper ?? <>{}</>,
                        {},
                        <h2>{title}</h2>,
                    )}
                </div>
                <div data-testid="usp-layout" className={classNames(
                    styles.Usps,
                    { [styles.Usps__advanced as string]: layoutAdvanced },
                    { [styles.Usps__basic as string]: layoutBasic },
                )}>
                    {uspItems.map((usp) => {
                        const item = (
                            <>
                                {usp.imageUri && (
                                    <div className={styles.Usps__item__image}>
                                        <Picture imageUrl={withStaticUrl(usp.imageUri)} alt={usp.title}>
                                            <Source background={false} imageWidth={ layoutAdvanced ? 20 : 32} />
                                        </Picture>
                                    </div>
                                )}
                                <div className={styles.Usps__item__content}>
                                    <span className={styles.Usps__item__content__title}>{usp.title}</span>
                                    <span className={styles.Usps__item__content__subtitle}>{usp.text}</span>
                                </div>
                                {layoutAdvanced && usp.url && (
                                    <button className={classNames('button button--link button--has-icon', [styles.Usps__item__button__link])}>
                                        <i className="fa-light fa-arrow-right-long"></i>
                                    </button>
                                )}
                            </>
                        );

                        return usp.url
                            ? <a key={usp.id} className={styles.Usps__item} href={usp.url}>{item}</a>
                            : <div key={usp.id} className={styles.Usps__item}>{item}</div>;
                    })}
                </div>
            </div>
        </div>
    );
});

export default Usps;
