import type React from 'react';
import Breadcrumbs from './Breadcrumbs';
import Section from '../../Section/Section';

interface BreadcrumbWrapperProps {
    breadcrumbs?: Record<string, string>;
}

const BreadcrumbsWrapper: React.FC<BreadcrumbWrapperProps> = ({ breadcrumbs }) => (
    <Section isBreadcrumb noPadding>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Section>
);

export default BreadcrumbsWrapper;
