import React from 'react';
import type { Tracker } from '@yoursurprise/segment-analytics';
import { useInstantSearch } from 'react-instantsearch-core';
import type { SearchResults } from 'algoliasearch-helper';
import { MemoProductBox } from '../../../general/ProductBox/ProductBox';
import type { AlgoliaProduct } from '../Input';
import { createOnProductClicked } from '../createOnProductClicked';
import type { ExtractProps } from '../../../types';
import Slider from '../../../general/Slider/Slider';

type ProductListProps = {
    asSlider?: boolean;
    currency: string;
    distinct?: boolean;
    id?: string;
    index: string;
    maxResults?: number;
    searchResults: SearchResults;
    tracker: Tracker | undefined;
};

const ProductList = React.forwardRef<HTMLDivElement, ProductListProps>(({
    asSlider = false,
    currency,
    distinct,
    id,
    index,
    maxResults,
    searchResults,
    tracker,
}, ref) => {
    const onProductClicked = createOnProductClicked(tracker, currency, index, searchResults?.queryID);
    const hits = searchResults.hits as AlgoliaProduct[];

    const products = hits.map((hit, position) => {
        if (maxResults && position >= maxResults) {
            return null;
        }

        return (
            <MemoProductBox
                alt={hit.productH1}
                key={hit.productId}
                amount={distinct ? hit.combiArticleCount : 0}
                href={hit.url}
                image={hit.image}
                onProductClicked={() => onProductClicked(hit, position)}
                price={{ currency, value: hit.price.current }}
                priceFrom={{ currency, value: hit.price.from }}
                lowestPrice={distinct && hit.combiArticleCount > 1 ? {
                    currency,
                    value: hit.lowestPrice,
                } : undefined}
                title={distinct ? hit.name : hit.productTitle}
                hasFreeCoolerNotification={hit.hasFreeCoolerNotification}
            />
        );
    });

    if (asSlider) {
        return (
            <div ref={ref} id={id}>
                <Slider hideNavigationButtons={true}>{products}</Slider>
            </div>
        );
    }

    return (
        <div
            ref={ref}
            className="product-list is-grid has-col-4-xl has-col-3-md has-col-2 has-no-col-gap-lg has-no-row-gap-lg js-productList"
            id={id}
        >
            {products}
        </div>
    );
});

const ProductListWithAlgolia: React.FC<Omit<ProductListProps, 'hits' | 'queryId'> & { searchResults: SearchResults }> = ({
    asSlider,
    currency,
    distinct,
    id,
    index,
    maxResults,
    searchResults,
    tracker,
}) => {
    if (searchResults.hits.length) {
        return <ProductList asSlider={asSlider} currency={currency} searchResults={searchResults} id={id} index={index} tracker={tracker} distinct={distinct} maxResults={maxResults}/>;
    }

    return null;
};

export const ConnectedProductList = React.forwardRef<HTMLDivElement, Omit<ExtractProps<typeof ProductListWithAlgolia>, 'searchResults'>>((props, ref) => {
    const { results: searchResults } = useInstantSearch();

    return <div ref={ref}><ProductListWithAlgolia {...props} searchResults={searchResults} /></div>;
});

export default ProductList;
