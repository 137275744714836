import React, { useEffect, useRef, useState } from 'react';
import type { ReactElement } from 'react';
import Translation from '../../Translation/Translation';

interface DescriptionProps {
    text: string;
    textWrapper?: ReactElement;
}

const LINE_HEIGHT = 21;

const Description: React.FC<DescriptionProps> = ({ text, textWrapper }) => {
    const [closedHeight, setClosedHeight] = useState(LINE_HEIGHT * 2);
    const [openHeight, setOpenHeight] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [readMoreVisible, setReadMoreVisible] = useState(false);
    const [className, setClassName] = useState('page-description page-description--closed');
    const ref = useRef<HTMLInputElement>(null);

    const showMore = () => {
        setIsOpen(true);
        setClassName('page-description page-description--open');
    };

    useEffect(() => {
        const componentScrollHeight = ref.current?.children[0]?.scrollHeight ?? 0;
        if (componentScrollHeight > (LINE_HEIGHT * 3)) {
            setReadMoreVisible(true);
        } else {
            setClosedHeight(componentScrollHeight);
        }

        setOpenHeight(componentScrollHeight);
    }, [readMoreVisible]);

    if (!textWrapper && (text.trim()).length === 0) {
        return null;
    }

    return (
        <div className="wrapper">
            {textWrapper && (
                React.cloneElement(
                    textWrapper,
                    {},
                    <div data-testid="description-text" className="page-description page-description-manager">
                        <p>{text}</p>
                    </div>,
                )
            )}

            {!textWrapper && (
                <>
                    <div ref={ref} className={className}>
                        <p style={isOpen ? { height: `${openHeight}px` } : {
                            height: `${closedHeight}px`,
                            WebkitLineClamp: closedHeight / LINE_HEIGHT,
                        }} className="page-description-text">{text}</p>
                    </div>
                    {readMoreVisible && !isOpen && (
                        <div onClick={showMore} className="page-description-read-more button button--link">
                            <Translation pageString="productinformatie" stringId={'read_more'}/>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Description;
