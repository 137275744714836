import type { ReactElement } from 'react';
import React from 'react';
import Markdown from 'markdown-to-jsx';
import Section from '../../../../general/Section/Section';

interface SeoTextProps {
    isExpanded?: boolean;
    text: string;
    wrapper?: ReactElement;
}

const SeoText: React.FC<SeoTextProps> = ({ text, wrapper }) => {
    const seoText = text.trim();

    return (
        <>
            {!!seoText && (
                <Section isXl hasWaveTop wrapperMd>
                    <div className="seo seo--new lh-lg">
                        <div className="seo__text">
                            {wrapper
                                ? React.cloneElement(wrapper, {}, seoText)
                                : <Markdown options={{ disableParsingRawHTML: true }}>{seoText}</Markdown>}
                        </div>
                    </div>
                </Section>
            )}
        </>
    );
};

export default SeoText;
