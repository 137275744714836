import type React from 'react';
import type { FC } from 'react';
import classNames from 'classnames';
import Source from '../../Picture/Source';
import Picture from '../../Picture/Picture';
import styles from './CategoryBlock.module.scss';

type CategoryBlockProps = {
    children: React.ReactElement | React.ReactElement[];
};

type CategoryBlockItemProps = {
    colorScheme: 'blue' | 'yellow' | 'pink';
    imageUrl: string;
    itemUrl: string;
    subtitle: string;
    title : string;
};

const Item: FC<CategoryBlockItemProps> = ({ colorScheme, imageUrl, itemUrl, subtitle, title }) => <a href={itemUrl} className={classNames(
    styles.CategoryBlock__item,
    { [styles.CategoryBlock__item_yellow as string]: colorScheme === 'yellow' },
    { [styles.CategoryBlock__item_blue as string]: colorScheme === 'blue' },
    { [styles.CategoryBlock__item_pink as string]: colorScheme === 'pink' },
)}>
    <Picture alt={title} imageUrl={imageUrl}>
        <Source background={false} imageWidth={140}/>
    </Picture>
    <div className={styles.CategoryBlock__item__content}>
        <p className="h3 mt-2">{title}</p>
        <p className="mt-2 c-text--light">{subtitle}</p>
    </div>
</a>;

const CategoryBlock: React.FC<CategoryBlockProps> & { Item: typeof Item } = ({ children }) => <div className={styles.CategoryBlock}>
    { children }
</div>;

CategoryBlock.Item = Item;

export default CategoryBlock;
