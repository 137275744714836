import React from 'react';
import type { ReactElement } from 'react';
import classNames from 'classnames';
import type { Categories as CategoriesType } from '../ComponentTypes';
import { useIsInitialRequestMobile, useWithStaticUrl } from '../../WebshopContext/WebshopContext';
import Box from '../Box/Box';
import Picture from '../../Picture/Picture';
import Slider from '../../Slider/Slider';
import styles from './ImageSlider.module.scss';
import Source from '../../Picture/Source';
import { useIsMobile } from '../../hooks/useSize';

interface CategoriesProps {
    categories: CategoriesType['links'];
    draggableWrapper?: ReactElement;
    isDraggable?: boolean;
    itemWrapper?: ReactElement;
}

const ImageSlider: React.FC<CategoriesProps> = (({ categories, draggableWrapper, isDraggable, itemWrapper }) => {
    const withStaticUrl = useWithStaticUrl();
    const blankImage = 'https://assets.yoursurprise.com/images/blank.gif';
    const isMobileOrTablet = useIsMobile(useIsInitialRequestMobile());

    const images = categories.map((category, i) => {
        if (!itemWrapper && !category.imageUri) {
            return null;
        }

        const box = (
            <div key={category.id} className={classNames({ deactivated: !category.show })} data-testid="image-box">
                <Box editable={!!itemWrapper} index={0} item={{ ...category, imageUri: category.imageUri ? withStaticUrl(category.imageUri) : blankImage, title: category.name }} type="large">
                    <Picture alt={category.name} imageUrl={category.imageUri ? withStaticUrl(category.imageUri) : blankImage} draggable={false}>
                        <Source imageWidth={322} framing="crop" smartImage={true} minScreenWidth={768} />
                        <Source imageWidth={225} framing="crop" smartImage={true} />
                    </Picture>
                </Box>
            </div>
        );

        return itemWrapper ? React.cloneElement(itemWrapper, { id: category.id, index: i, isAdditional: category.isAdditional, key: category.id }, box) : box;
    }).filter((category) => category !== null);

    return (
        <div className={styles.ImageSlider}>
            {isDraggable
                ? <div className="is-grid has-col-4-xl has-col-2 has-row-gap-md has-col-3-md" style={{ paddingTop: '40px' }}>
                    {React.cloneElement(draggableWrapper ?? <>{}</>, {}, images)}
                </div>
                : <Slider hideNavigationButtons={isMobileOrTablet}>{images}</Slider>
            }
        </div>
    );
});

export default ImageSlider;
