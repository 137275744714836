import React, { useEffect, useRef } from 'react';
import type { ReactElement } from 'react';
import classNames from 'classnames';
import type { Categories as CategoriesType } from '../ComponentTypes';
import Button from '../../Button/Button';
import { slideScroll } from '../../../../js/util/slideScroll';

interface CategoriesProps {
    categories: CategoriesType['links'];
    draggableWrapper?: ReactElement;
    isDraggable?: boolean;
    itemWrapper?: ReactElement;
}

const ButtonSlider: React.FC<CategoriesProps> = (({ categories, draggableWrapper, isDraggable, itemWrapper }) => {
    const groupRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => slideScroll(groupRef.current), [groupRef]);

    const links = categories.map((category, i) => {
        const link = (
            <div data-testid={`categories ${category.id}`} key={category.id} className="product-category">
                <a
                    href={category.url}
                    title={category.name}
                    className={classNames(
                        'button button--ghost button--xs',
                        { 'deactivated ': !category.show },
                        { 'pointer-events--none ': itemWrapper },
                    )}
                >
                    <p>{category.name}</p>
                </a>
            </div>
        );

        return itemWrapper ? React.cloneElement(itemWrapper, { id: category.id, index: i, isAdditional: category.isAdditional, key: category.id }, link) : link;
    });

    return (
        <>
            {isDraggable && (
                <div className="product-category-group">
                    <div ref={groupRef} className="product-subcategory-group product-subcategory-group--wrap" data-top-product-list-group={1}>
                        {React.cloneElement(draggableWrapper ?? <>{}</>, {}, links)}
                    </div>
                </div>
            )}

            {!isDraggable && (
                <div className="slide-scroll">
                    <Button aria-label="Previous" iconOnly isLight className="slide-scroll--start button--round" icon="fa-chevron-left" />
                    <Button aria-label="Next" iconOnly isLight className="slide-scroll--end button--round" icon="fa-chevron-right" />
                    <div className="product-category-group">
                        <div ref={groupRef} className="product-subcategory-group" data-top-product-list-group={1}>
                            {links}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default ButtonSlider;
